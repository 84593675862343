import ScheduleSeach from './ScheduleSeach.vue'
import axios from 'axios'
import jwt_decode from "jwt-decode";
export default {
    components:{
        ScheduleSeach
    },
    data(){
        return {
            startDate: '',//开始时间
            endDate: '',//结束时间
            shippingSchedule:[],//船期列表
            currentPage:1,//页码
            count:0,//总数
            pageSize:10,//每页显示条数
            sList1: [
                {
                    value: 'China',
                    label: '中国',
                    children: [
                        { label: '上海',value:599 },
                        { label: '厦门',value:627 },
                        { label: '宁波',value:575 },
                        { label: '青岛',value:590},
                        { label: '广州',value:554 },
                        { label: '天津',value:620},
                        { label: '大连',value:543 },
                        { label: '福州', value: 552 },
                        { label: '蛇口',value:3697 },
                        { label: '盐田',value:635 },
                    ]
                },
                {
                    value: 'ChinaHK',
                    label: '中国香港',
                    children: [
                        { label: '香港',value:1564 },
                    ]
                },
                {
                    value: 'ChinaTW',
                    label: '中国台湾',
                    children: [
                        { label: '台北',value:618 },
                        { label: '高雄',value:3269 },
                        { label: '基隆',value:3266 },
                        { label: '台中',value:3271 },
                    ]
                },
                {
                    value: 'Korea',
                    label: '韩国',
                    children: [
                        { label: '釜山',value:3116 },
                        { label: '仁川',value:3123 },
                        { label: '光阳',value:2229 },
                        { label: '蔚山',value:3130 },
                    ]
                },
            ],
            sList:[
                { label: '上海(中国)',value:599 },
                { label: '厦门(中国)',value:627 },
                { label: '宁波(中国)',value:575 },
                { label: '青岛(中国)',value:590},
                { label: '广州(中国)',value:554 },
                { label: '天津(中国)',value:620},
                { label: '大连(中国)',value:543 },
                { label: '福州(中国)',value:552},
                { label: '香港(中国香港)',value:1564 },
                { label: '台北(中国台湾)',value:618 },
                { label: '高雄(中国台湾)',value:3269 },
                { label: '基隆(中国台湾)',value:3266 },
                { label: '台中(中国台湾)',value:3271 },
                { label: '蛇口(中国)',value:3697 },
                { label: '盐田(中国)',value:635 },
                { label: '釜山(韩国)',value:3116 },
                { label: '仁川(韩国)',value:3123 },
                { label: '光阳(韩国)',value:2229 },
                { label: '蔚山(韩国)',value:3130 },
                // { label: 'GWANGYANG(韩国)',value:4174 },
            ],//起运港列表
            startPortList:[],
            tabShow: 1,
            endPortList:[],//目的港列表
            form1: {
                startPort:[],
                startPortId: '',
                endPortId: '',
                etd: '',
                scid: [],
                sorter: []
            },
            rules: {
                startPort: [
                    { required: true, message: '请选择起运港', trigger: 'change' }
                ],
                endPortId: [
                    { required: true, message: '请选择目的港', trigger: 'change' }
                ],
                etd: [
                    { required: true, message: '请选择日期', trigger: 'change' }
                ]
            },
            form2: {
                shipName: '',
                scid: [],
                sorter: [],
                datewl: '1',
                etd:[]
            },
            rules2: {
                shipName: [
                    { required: true, message: '请输入船名', trigger: 'blur' }
                ],
                datewl: [
                    { required: true, message: '请选择日期', trigger: 'change' }
                ]
            },
            form3: {
                startPortId: '',
                etd: '',
                scid:[],
                sorter: []
            },
            rules3: {
                startPortId: [
                    { required: true, message: '请选择港口', trigger: 'change' },
                ],
                etd: [
                    { required: true, message: '请选择日期', trigger: 'change' },
                ]
            },
            carrierList: [],
            isIndeterminate: true,
            checkAll: true,
            shipNameList: [],
            pickerOptions: {
                // 时段不能超过八周
                onPick: function ({ minDate, maxDate }) {
                    if (minDate && !maxDate) {
                        const currentDateTimestamp = minDate.getTime();
                        const minDateTimestamp = currentDateTimestamp - 3600 * 1000 * 24 * 56;
                        const maxDateTimestamp = currentDateTimestamp + 3600 * 1000 * 24 * 56;
                        this.disabledDate = date => {
                            const dateTimestamp = date.getTime();
                            return dateTimestamp <= minDateTimestamp || dateTimestamp >= maxDateTimestamp;
                        };
                    }
                }
            },
            pickerOptionsFour: {
                // 只能选中今后的一个月内的某一天
                disabledDate(val){
                    return val.getTime() < new Date().getTime() - 86400000 || val.getTime() > new Date().getTime() + 3600 * 1000 * 24 * 31;
                },
            }
        }
    },
    filters:{
        sailSchedule(value){
            if(value == 1){
                return 'MON'
            }else if(value == 2){
                return 'TUE'
            }else if(value == 3){
               return 'WED' 
            }else if(value == 4){
                return 'THU'
            }else if(value == 5){
                return 'FRI'
            }else if(value == 6){
                return 'SAT'
            }else if(value == 0){
                return 'SUN'
            }
        }
    },
    created(){
        // 日期初始值
       var now = new Date();
       var now1 = new Date();
       now1.setMonth(now.getMonth()+1); 
       this.startDate = now
       this.endDate = now1
       this.form1.etd = [now, now1]
       this.form3.etd = now
    },
    methods: {
        changeTab(val) {
            this.tabShow = val
            this.PortReset()
        },
        // 港口模糊查询
        searchPort(val) {
            axios.defaults.headers.common['Content-Type'] = 'application/json'
            axios.post(this.GLOBAL.BASE_URL4 +'/client/freight/sea/port',{
                q:val
            })
            .then(res => {
                if (this.tabShow == 3) {
                    this.startPortList = res.data
                } else {
                    this.endPortList = res.data
                }
            })
            .catch(error=>{
                console.log(error)
            })
        },
        // 点到点查询
        PortSearch(val) {
            this.$refs.form1.validate(valid => {
                if (valid) {
                    this.form1.username = localStorage.getItem('DDTtoken') ? jwt_decode(localStorage.getItem('DDTtoken')).name : 'tourist'
                    this.form1.currentPage = this.currentPage
                    this.form1.pageSize = this.pageSize
                    this.form1.startPortId = this.form1.startPort[1]
                    if (!val) {
                        this.form1.scid = []
                    }
                    this.form1.etd[0] = this.$moment(this.form1.etd[0]).format('YYYY-MM-DD')
                    this.form1.etd[1] = this.$moment(this.form1.etd[1]).format('YYYY-MM-DD')
                    this.publicQuery(this.form1,val)
                }
            })
        },
        // 点到点 船公司筛选
        AllChange(val) {
            this.form1.scid = val ? this.carrierList.map(item => {
                return item.scid
            }) : [];
            this.isIndeterminate = false;
            this.PortSearch('carrier')
        },
        CarriersChange(value) {
            this.currentPage = 1
            this.PortSearch('carrier')
            let checkedCount = value.length;
            this.checkAll = checkedCount === this.carrierList.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.carrierList.length;
        },
        // 重置
        PortReset() {
            this.$refs.form1.resetFields();
            this.$refs.form2.resetFields();
            this.$refs.form3.resetFields();
            this.endPortList = []
            this.shippingSchedule = []
            this.pageSize = 10
            this.currentPage = 1
            this.count=0
            this.form1.scid = []
            this.form2.scid = []
            this.form3.scid = []
            this.carrierList = []
            this.isIndeterminate= true
            this.checkAll = true
            this.form1.startPortId = ''
            this.form1.sorter = []
            this.form2.sorter = []
            this.form3.sorter = []
            this.$refs.table.clearSort()
            this.$refs.table2.clearSort()
            this.$refs.table3.clearSort()
            this.form2.datewl = '1'
        },
        // 船名模糊搜索
        secrchShipName(val) { 
            axios.get(this.GLOBAL.BASE_URL4 + '/client/schedule/like',{
                params:{
                    shipName: val
                }
            })
            .then(res => {
                if(res.data.data.length != 0){
                    this.shipNameList = res.data.data
                }else {
                    this.shipNameList = []
                }
            })
            .catch(error=>{
                console.log(error);
            })
        },
        // 根据船名查询
        searchShipName(val) {
            this.$refs.form2.validate(valid => {
                if (valid) {
                    this.form2.username = localStorage.getItem('DDTtoken') ? jwt_decode(localStorage.getItem('DDTtoken')).name : 'tourist'
                    this.form2.currentPage=this.currentPage
                    this.form2.pageSize = this.pageSize
                    if (!val) {
                        this.form2.scid = []
                    }
                    var msg = this.form2
                    if (this.form2.datewl == '1') {
                        msg.etd[0] = this.$moment(new Date()).format('YYYY-MM-DD')
                        msg.etd[1] = this.$moment(new Date()).add(7,"days").format('YYYY-MM-DD')
                    } else if (this.form2.datewl == '2') {
                        msg.etd[0] = this.$moment(new Date()).format('YYYY-MM-DD')
                        msg.etd[1] = this.$moment(new Date()).add(14,"days").format('YYYY-MM-DD')
                    } else if (this.form2.datewl == '3') {
                        msg.etd[0] = this.$moment(new Date()).format('YYYY-MM-DD')
                        msg.etd[1] = this.$moment(new Date()).add(21,"days").format('YYYY-MM-DD')
                    } else if (this.form2.datewl == '4') {
                        msg.etd[0] = this.$moment(new Date()).format('YYYY-MM-DD')
                        msg.etd[1] = this.$moment(new Date()).add(28,"days").format('YYYY-MM-DD')
                    }
                    this.publicQuery(msg,val)
                }
            })
        },
        // 船只 船公司筛选
        AllChange2(val) {
            this.form2.scid = val ? this.carrierList.map(item => {
                return item.scid
            }) : [];
            this.isIndeterminate = false;
            this.searchShipName('carrier')
          },
        CarriersChange2(value) {
            this.currentPage = 1
            this.searchShipName('carrier')
            let checkedCount = value.length;
            this.checkAll = checkedCount === this.carrierList.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.carrierList.length;
        },
        // 港口查询
        async PortsSeach(val) {
            await this.$refs.form3.validate(valid => {
                if (valid) {
                    this.form3.username =  localStorage.getItem('DDTtoken')? jwt_decode(localStorage.getItem('DDTtoken')).name : 'tourist'
                    var form3Msg = JSON.parse(JSON.stringify(this.form3))
                    form3Msg.etd = [this.$moment(form3Msg.etd).format('YYYY-MM-DD'), this.$moment(form3Msg.etd).format('YYYY-MM-DD')]
                    this.form3.currentPage=this.currentPage
                    this.form3.pageSize = this.pageSize
                    if (!val) {
                        this.form3.scid = []
                    }
                    this.publicQuery(form3Msg, val)
                }
            })
        },
        // 港口 船公司筛选
        AllChange3(val) {
            this.form3.scid = val ? this.carrierList.map(item => {
                return item.scid
            }) : [];
            this.isIndeterminate = false;
            this.PortsSeach('carrier')
          },
        CarriersChange3(value) {
            this.currentPage = 1
            this.PortsSeach('carrier')
            let checkedCount = value.length;
            this.checkAll = checkedCount === this.carrierList.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.carrierList.length;
        },
        
        // 船公司排序
        sortList(arr,flag){
            return arr.sort((a,b) => { 
                return a[flag].localeCompare(b[flag])
           })
        },
        // 船期公共查询 
        publicQuery(data, carrier) {
            data = JSON.parse(JSON.stringify(data))
            delete data.startPort 
            delete data.datewl 
            axios.defaults.headers.common['Content-Type'] = 'application/json'
            axios.get(this.GLOBAL.BASE_URL4 + '/client/schedule/queryDadaex',{
                params: data
            })
            .then(res => {
                if(res.data.status == 1){
                    this.count = res.data.result.count
                    this.shippingSchedule = res.data.result.rows
                    if (!carrier) {
                        this.carrierList = res.data.carriers
                        this.sortList(this.carrierList,['shipCompany.nameEn'])
                        this.form1.scid = this.carrierList.map(item => {
                            return item.scid
                        });
                        this.form2.scid = this.carrierList.map(item => {
                            return item.scid
                        });
                        this.form3.scid = this.carrierList.map(item => {
                            return item.scid
                        });
                        this.checkAll = true
                        this.isIndeterminate = false;
                    }
                } else {
                    this.$message({
                        type: 'warning',
                        message: res.data.message,
                        showClose: true,
                        center: true
                    });
                }
            })
            .catch(error=>{
                console.log(error)
            })
        },
        // 分页变化
        change() {
            var data = {}
            if (this.tabShow == 1 && this.form1.endPortId != '') {
                data = {
                    startPortId: this.form1.startPortId,
                    endPortId: this.form1.endPortId,
                    etd: this.form1.etd,
                    scid: this.form1.scid,
                    currentPage: this.currentPage,
                    pageSize: this.pageSize,
                    sorter: this.form1.sorter,
                    username: localStorage.getItem('DDTtoken')? jwt_decode(localStorage.getItem('DDTtoken')).name : 'tourist'
                }
            } else if (this.tabShow == 2) {
                data = {
                    shipName: this.form2.shipName,
                    etd: this.form2.etd,
                    scid: this.form2.scid,
                    currentPage: this.currentPage,
                    pageSize: this.pageSize,
                    sorter: this.form2.sorter,
                    username: localStorage.getItem('DDTtoken')? jwt_decode(localStorage.getItem('DDTtoken')).name : 'tourist'
                }
            } else if (this.tabShow == 3) {
                data = {
                    startPortId: this.form3.startPortId,
                    etd: [this.$moment(this.form3.etd).format('YYYY-MM-DD'),this.$moment(this.form3.etd).format('YYYY-MM-DD')],
                    scid: this.form3.scid,
                    currentPage: this.currentPage,
                    pageSize: this.pageSize,
                    sorter: this.form3.sorter,
                    username: localStorage.getItem('DDTtoken')? jwt_decode(localStorage.getItem('DDTtoken')).name : 'tourist'
                }
            } else {
                data = {
                    etd:[this.$moment(this.startDate).format('YYYY-MM-DD'),this.$moment(this.endDate).format('YYYY-MM-DD')],
                    currentPage: this.currentPage,
                    pageSize: this.pageSize,
                    sorter: this.form1.sorter,
                    username: localStorage.getItem('DDTtoken')? jwt_decode(localStorage.getItem('DDTtoken')).name : 'tourist'
                }
            }
            this.publicQuery(data,'carrier')
        },
        handleSizeChange(val) {//每页条数
            this.pageSize = val
            this.change()
          },
        handleCurrentChange(val) {//当前页
            this.currentPage = val
            this.change()
        },
        // 表格排序
        sortChange(column) {
            if(column.order == "ascending") {//升序
                this.form1.sorter=JSON.stringify([[column.prop,'asc']])
              } else if(column.order == "descending") { // 降序
                this.form1.sorter=JSON.stringify([[column.prop,'desc']])
            }
            this.form1.username = localStorage.getItem('DDTtoken') ? jwt_decode(localStorage.getItem('DDTtoken')).name : 'tourist'
            this.form1.currentPage= this.currentPage,
            this.form1.pageSize = this.pageSize,
            this.form1.etd[0] = this.$moment(this.form1.etd[0]).format('YYYY-MM-DD')
            this.form1.etd[1] = this.$moment(this.form1.etd[1]).format('YYYY-MM-DD')
            this.publicQuery(this.form1)
        },
        sortChangeShip(column) {
            if(column.order == "ascending") {//升序
                this.form2.sorter=JSON.stringify([[column.prop,'asc']])
              } else if(column.order == "descending") { // 降序
                this.form2.sorter=JSON.stringify([[column.prop,'desc']])
            }
            this.form2.username = localStorage.getItem('DDTtoken') ? jwt_decode(localStorage.getItem('DDTtoken')).name : 'tourist'
            this.form2.currentPage= this.currentPage,
            this.form2.pageSize=this.pageSize,
            this.publicQuery(this.form2)
        },
        sortChangePort(column) {
            if (column.order == "ascending") {//升序
                this.form3.sorter = JSON.stringify([[column.prop, 'asc']])
            } else if (column.order == "descending") { // 降序
                this.form3.sorter = JSON.stringify([[column.prop, 'desc']])
            }
            this.form3.username = localStorage.getItem('DDTtoken') ? jwt_decode(localStorage.getItem('DDTtoken')).name : 'tourist'
            this.form3.currentPage= this.currentPage
            this.form3.pageSize = this.pageSize
            var form3Msg = JSON.parse(JSON.stringify(this.form3))
            form3Msg.etd = [this.$moment(form3Msg.etd).format('YYYY-MM-DD'), this.$moment(form3Msg.etd).format('YYYY-MM-DD')]
            this.publicQuery(form3Msg)
        }
    }
}