<template>
  <div class="ScheduleSeach">
      <div class="scheduleHead">
           <el-menu
            default-active="1"
            class="el-menu-vertical-demo"
            background-color="#fff"
            text-color="#F0801B"
            active-text-color="#F0801B">
            <el-menu-item index="1" @click="changeTab(1)">
                 <i class="el-icon-position"></i>
                <span slot="title">点到点</span>
            </el-menu-item>
            <el-menu-item index="2" @click="changeTab(2)">
                <i class="el-icon-ship"></i>
                <span slot="title">船只</span>
            </el-menu-item>
            <el-menu-item index="3" @click="changeTab(3)">
                <i class="el-icon-location-outline"></i>
                <span slot="title">港口</span>
            </el-menu-item>
        </el-menu>
      </div>
      <div class="scheduleCont" v-show="tabShow == 1">
          <div class="s_cont_head">
              <el-form label-width="120px" :model="form1" :rules="rules" ref="form1" >
                 <el-row :gutter="10">
                    <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
                        <el-form-item label="起运港" prop="startPort">
                            <el-cascader
                                v-model="form1.startPort"
                                :options="sList1">
                            </el-cascader>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
                        <el-form-item label="目的港" prop="endPortId">
                            <el-select
                                v-model="form1.endPortId"
                                filterable
                                remote
                                reserve-keyword
                                clearable 
                                :remote-method="searchPort"
                                >
                                <el-option
                                    v-for="item in endPortList"
                                    :key="item.id"
                                    :label="item.english_name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col  :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                        <el-form-item label="预计开航时间" prop="etd">
                            <el-date-picker
                                v-model="form1.etd"
                                type="daterange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                value-format="yyyy-MM-dd"
                                :picker-options="pickerOptions">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                     <el-col :xs="24" :sm="24" :md="12" :lg="4" :xl="4">
                        <div class="head_btn">
                            <el-button type="success" @click="PortSearch()">查询</el-button>
                            <el-button type="danger" @click="PortReset()">重置</el-button>
                        </div>
                     </el-col>
                </el-row>
            </el-form>
          </div>
          <div class="s_conts">
              <div class="s_cont_carrier" v-if="carrierList.length > 0">
                <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="AllChange">全选</el-checkbox>
                <div style="margin: 5px 0;"></div>
                <el-checkbox-group v-model="form1.scid" @change="CarriersChange">
                    <el-checkbox v-for="carr in carrierList" :label="carr.scid" :key="carr.scid" >{{carr['shipCompany.nameEn']}}({{carr.sum}})</el-checkbox>
                </el-checkbox-group>
            </div>
            <div class="s_cont_table">
                <el-table
                    :data="shippingSchedule"
                    ref="table"
                    stripe
                    @sort-change="sortChange"
                    style="width: 100%">
                    <el-table-column
                    label="起运港"
                    prop="startPortId"
                    sortable="custom"
                    min-width="180">
                    <template slot-scope="scope">
                        <span>{{scope.row.port.nameEn || scope.row.port.name}}</span>
                    </template>
                    </el-table-column>
                    <el-table-column
                    prop="scid"
                    label="船公司"
                    sortable="custom"
                    min-width="100">
                    <template slot-scope="scope">
                        <span>{{scope.row.shipCompany.nameEn || scope.row.shipCompany.name}}</span>
                    </template>
                    </el-table-column>
                    <el-table-column
                    prop="code"
                    label="航线代码"
                    sortable="custom"
                    min-width="120">
                    </el-table-column>
                    <el-table-column
                    prop="etd"
                    label="ETD"
                    sortable="custom"
                    min-width="120">
                    </el-table-column>
                    <el-table-column
                    prop="eta"
                    label="ETA"
                    sortable="custom"
                    min-width="120">
                    </el-table-column>
                    <el-table-column
                    prop="schedule"
                    label="船期"
                    sortable="custom"
                    min-width="100">
                    <template slot-scope="scope">
                        <span>{{scope.row.schedule | sailSchedule}}</span>
                    </template>
                    </el-table-column>
                    <el-table-column
                    prop="shipName"
                    label="船名"
                    sortable="custom"
                    min-width="190">
                    </el-table-column>
                    <el-table-column
                    prop="voyage"
                    label="航次"
                    min-width="120">
                    </el-table-column>
                    <el-table-column
                    prop="address"
                    label="操作">
                    <template>
                        <!--  slot-scope="scope" -->
                        <span>操作按钮</span>
                    </template>
                    </el-table-column>
                    <div slot="empty" class="emptyBg">
                        <img src="../../assets/empty.png" alt="">
                        <p>没有记录哦~</p>
                    </div>
                </el-table>
            </div>
            <div class="page">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    :page-sizes="[10, 20, 30, 40, 50, 100]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="count">
                </el-pagination>
            </div> 
          </div>
      </div>
      <div class="scheduleCont" v-show="tabShow == 2">
          <div class="s_cont_head">
              <el-form label-width="120px" :model="form2" :rules="rules2" ref="form2" >
                 <el-row :gutter="10">
                    <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
                        <el-form-item label="船名" prop="shipName">
                            <el-select v-model="form2.shipName" 
                                filterable 
                                remote
                                :remote-method="secrchShipName"
                                placeholder="请输入船名"
                                >
                                <el-option
                                v-for="item in shipNameList"
                                :key="item.shipName"
                                :label="item.shipName"
                                :value="item.shipName">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                     <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
                        <el-form-item label="日期范围" prop="datewl">
                             <el-select v-model="form2.datewl" placeholder="请选择">
                                <el-option label="一周" value="1"></el-option>
                                <el-option label="两周" value="2"></el-option>
                                <el-option label="三周" value="3"></el-option>
                                <el-option label="四周" value="4"></el-option>
                             </el-select>
                        </el-form-item>
                     </el-col>
                     <el-col  :xs="24" :sm="24" :md="12" :lg="4" :xl="4">
                        <div class="head_btn">
                            <el-button type="success" @click="searchShipName()">查询</el-button>
                            <el-button type="danger" @click="PortReset()">重置</el-button>
                        </div>
                     </el-col>
                </el-row>
            </el-form>
          </div>
           <div class="s_conts">
               <div class="s_cont_carrier" v-if="carrierList.length > 0">
                <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="AllChange2">全选</el-checkbox>
                <div style="margin: 5px 0;"></div>
                <el-checkbox-group v-model="form2.scid" @change="CarriersChange2">
                    <el-checkbox v-for="carr in carrierList" :label="carr.scid" :key="carr.scid" >{{carr['shipCompany.nameEn']}}({{carr.sum}})</el-checkbox>
                </el-checkbox-group>
              </div>
               <div class="s_cont_table">
                <el-table
                    :data="shippingSchedule"
                    stripe
                    ref="table2"
                    @sort-change="sortChangeShip"
                    style="width: 100%">
                    <el-table-column
                    label="起运港"
                    prop="startPortId"
                    sortable="custom"
                    min-width="180">
                    <template slot-scope="scope">
                        <span>{{scope.row.port.nameEn || scope.row.port.name}}</span>
                    </template>
                    </el-table-column>
                    <el-table-column
                    prop="scid"
                    sortable="custom"
                    label="船公司"
                    min-width="100">
                    <template slot-scope="scope">
                        <span>{{scope.row.shipCompany.nameEn || scope.row.shipCompany.name}}</span>
                    </template>
                    </el-table-column>
                    <el-table-column
                    prop="code"
                    label="航线代码"
                    sortable="custom"
                    min-width="120">
                    </el-table-column>
                    <!-- <el-table-column
                    prop="eta"
                    label="ETA"
                    min-width="120">
                    </el-table-column> -->
                    <el-table-column
                    prop="etd"
                    label="ETD"
                    sortable="custom"
                    min-width="120">
                    </el-table-column>
                    <el-table-column
                    prop="schedule"
                    label="船期"
                    sortable="custom"
                    min-width="100">
                    <template slot-scope="scope">
                        <span>{{scope.row.schedule | sailSchedule}}</span>
                    </template>
                    </el-table-column>
                    <el-table-column
                    prop="shipName"
                    label="船名"
                    sortable="custom"
                    min-width="190">
                    </el-table-column>
                    <el-table-column
                    prop="voyage"
                    label="航次"
                    min-width="120">
                    </el-table-column>
                    <el-table-column
                    prop="address"
                    label="操作">
                    <template>
                        <!--  slot-scope="scope" -->
                        <span>操作按钮</span>
                    </template>
                    </el-table-column>
                    <div slot="empty" class="emptyBg">
                        <img src="../../assets/empty.png" alt="">
                        <p>没有记录哦~</p>
                    </div>
                </el-table>
               </div>
               <div class="page">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="currentPage"
                        :page-sizes="[10, 20, 30, 40, 50, 100]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="count">
                    </el-pagination>
               </div> 
          </div>
      </div>
      <div class="scheduleCont" v-show="tabShow == 3">
          <div class="s_cont_head">
              <el-form label-width="120px" :model="form3" :rules="rules3" ref="form3" >
                 <el-row :gutter="10">
                    <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
                        <el-form-item label="港口" prop="startPortId">
                            <el-select
                                v-model="form3.startPortId"
                                filterable
                                remote
                                reserve-keyword
                                clearable 
                                :remote-method="searchPort"
                                >
                                <el-option
                                    v-for="item in startPortList"
                                    :key="item.id"
                                    :label="item.english_name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                        <el-form-item label="预计开航时间" prop="etd">
                           <el-date-picker
                                v-model="form3.etd"
                                type="date"
                                placeholder="选择日期"
                                value-format="yyyy-MM-dd"
                                :picker-options="pickerOptionsFour">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                     <el-col  :xs="24" :sm="24" :md="12" :lg="4" :xl="4">
                        <div class="head_btn">
                            <el-button type="success" @click="PortsSeach()">查询</el-button>
                            <el-button type="danger" @click="PortReset()">重置</el-button>
                        </div>
                     </el-col>
                </el-row>
            </el-form>
          </div>
          <div class="s_conts">
              <div class="s_cont_carrier" v-if="carrierList.length > 0">
                <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="AllChange3">全选</el-checkbox>
                <div style="margin: 5px 0;"></div>
                <el-checkbox-group v-model="form3.scid" @change="CarriersChange3">
                    <el-checkbox v-for="carr in carrierList" :label="carr.scid" :key="carr.scid" >{{carr['shipCompany.nameEn']}}({{carr.sum}})</el-checkbox>
                </el-checkbox-group>
            </div>
            <div class="s_cont_table">
                <el-table
                    :data="shippingSchedule"
                    stripe
                    ref="table3"
                    @sort-change="sortChangePort"
                    style="width: 100%">
                    <el-table-column
                    label="起运港"
                    prop="startPortId"
                    sortable="custom"
                    min-width="180">
                    <template slot-scope="scope">
                        <span>{{scope.row.port.nameEn || scope.row.port.name}}</span>
                    </template>
                    </el-table-column>
                    <el-table-column
                    prop="scid"
                    sortable="custom"
                    label="船公司"
                    min-width="100">
                    <template slot-scope="scope">
                        <span>{{scope.row.shipCompany.nameEn || scope.row.shipCompany.name}}</span>
                    </template>
                    </el-table-column>
                    <el-table-column
                    prop="code"
                    sortable="custom"
                    label="航线代码"
                    min-width="120">
                    </el-table-column>
                    <el-table-column
                    prop="etd"
                    sortable="custom"
                    label="ETD"
                    min-width="120">
                    </el-table-column>
                    <!-- <el-table-column
                    prop="eta"
                    label="ETA"
                    min-width="120">
                    </el-table-column> -->
                    <el-table-column
                    prop="schedule"
                    sortable="custom"
                    label="船期"
                    min-width="100">
                    <template slot-scope="scope">
                        <span>{{scope.row.schedule | sailSchedule}}</span>
                    </template>
                    </el-table-column>
                    <el-table-column
                    prop="shipName"
                    label="船名"
                    sortable="custom"
                    min-width="190">
                    </el-table-column>
                    <el-table-column
                    prop="voyage"
                    label="航次"
                    min-width="120">
                    </el-table-column>
                    <el-table-column
                    prop="address"
                    label="操作">
                    <template>
                        <!--  slot-scope="scope" -->
                        <span>操作按钮</span>
                    </template>
                    </el-table-column>
                    <div slot="empty" class="emptyBg">
                        <img src="../../assets/empty.png" alt="">
                        <p>没有记录哦~</p>
                    </div>
                </el-table>
            </div>
            <div class="page">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    :page-sizes="[10, 20, 30, 40, 50, 100]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="count">
                </el-pagination>
            </div> 
          </div>
      </div>
  </div>
</template>

<script>
    import ScheduleSeach from './ScheduleSeach.js'
    export default ScheduleSeach
</script>

<style>
    @import './ScheduleSeach.css';
</style>